import React from "react"
import { useRef } from "react"
import { useState } from "react"
import styled from "styled-components"
import Card from "./card"
import { ScheduleForm } from "./forms"

const CardListContainer = styled.section`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  max-width: 100vw;
  width: 100%;
  min-height: 16rem;
  background-color: #f6f9fc;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: space-evenly;
  background-color: #f6f9fc;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23d1d4d6' fill-opacity='0.6'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
  @media (max-width: 799px) {
    margin-top: 24rem;
    padding: 2rem 0;
  }
`

// Simple services that don't require much planning making it easy to request them (ie cutting lawn or cleaning gutters)
const serviceList = [
  {
    title: "Lawn Mowing",
    desc: "Freshly cut lawn, without the hassle.",
    img: "lawnmower.jpg",
    svg: (
      <svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMaxYMin meet"
      >
        <path
          fill="#284778"
          d="M45.9,-49.9C57.2,-45.2,62.7,-28.6,67,-10.8C71.4,7,74.6,26,66.7,37.3C58.7,48.6,39.5,52.2,23.6,52.6C7.8,53.1,-4.8,50.3,-19.4,47.5C-34.1,44.7,-50.9,41.9,-54.9,32.8C-58.9,23.7,-50,8.2,-44,-4.6C-38,-17.4,-34.7,-27.4,-27.9,-33C-21,-38.6,-10.5,-39.6,3.4,-43.7C17.3,-47.7,34.5,-54.7,45.9,-49.9Z"
          transform="translate(100 100)"
        />
      </svg>
    ),
  },
  {
    title: "Roofing",
    desc: "Repair broken tiles, fix leaks and sealing.",
    img: "roof.jpg",
    svg: (
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#051937"
          d="M50.6,-19.5C58.2,7.1,52,35.1,38.4,42.7C24.7,50.2,3.5,37.3,-10.9,24.6C-25.2,11.9,-32.6,-0.5,-29.7,-20.7C-26.7,-40.8,-13.4,-68.7,4.1,-70C21.5,-71.3,42.9,-46.1,50.6,-19.5Z"
          transform="translate(100 100)"
        />
      </svg>
    ),
  },
  {
    title: "Tiling",
    desc: "Add ceramic floor tiles to your wall or floor.",
    img: "tile.jpg",
    svg: (
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#284778"
          d="M45,-57.2C55.2,-45,58.2,-27.9,55.6,-14.1C53,-0.2,44.7,10.4,37.4,20.1C30,29.8,23.6,38.6,14.7,43C5.8,47.3,-5.7,47.3,-20.8,46.4C-35.9,45.6,-54.6,44,-66.4,33.5C-78.3,23,-83.3,3.7,-74.3,-7.1C-65.2,-18,-42.2,-20.4,-27.5,-31.7C-12.8,-43.1,-6.4,-63.4,5.5,-69.9C17.4,-76.5,34.8,-69.3,45,-57.2Z"
          transform="translate(100 100)"
        />
      </svg>
    ),
  },
  {
    title: "Power Washing",
    desc: "Clean any exterior surface with a power washer.",
    img: "wash.jpg",
    svg: (
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#051937"
          d="M47.3,-25.6C53.6,-16.4,45.8,2.8,35.6,20C25.3,37.2,12.7,52.4,1.1,51.8C-10.5,51.2,-21.1,34.7,-33.1,16.5C-45.2,-1.7,-58.7,-21.7,-53.4,-30.3C-48.2,-38.9,-24.1,-36.2,-1.8,-35.1C20.5,-34.1,41,-34.8,47.3,-25.6Z"
          transform="translate(100 100)"
        />
      </svg>
    ),
  },
  {
    title: "Leaks",
    desc: "Fix a leaking faucet or broken water pipes.",
    img: "leak.jpg",
    svg: (
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#FCF6E8"
          d="M39.4,-52.1C47.1,-40.5,46.6,-24.3,45,-11.2C43.4,1.8,40.6,11.8,38.3,28.1C36,44.4,34.3,66.9,23,77C11.7,87.2,-9.2,84.9,-28,77.7C-46.8,70.5,-63.5,58.4,-65.2,43.5C-66.9,28.5,-53.5,10.7,-45.1,-2.9C-36.7,-16.6,-33.4,-26.2,-26.7,-37.9C-20,-49.7,-10,-63.6,2.9,-67.1C15.8,-70.6,31.7,-63.6,39.4,-52.1Z"
          transform="translate(100 100)"
        />
      </svg>
    ),
  },
  {
    title: "Wall Cracks",
    img: "wall-crack.jpg",
    desc: "Fix any cracks in your walls.",
    svg: (
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#FCF6E8"
          d="M56.8,-56.9C72.3,-41.4,82.6,-20.7,80.3,-2.4C77.9,16,62.9,31.9,47.4,40.7C31.9,49.4,16,50.9,-3.2,54.1C-22.4,57.3,-44.8,62.2,-54.7,53.5C-64.6,44.8,-62,22.4,-56.2,5.8C-50.3,-10.7,-41.3,-21.5,-31.4,-37.1C-21.5,-52.7,-10.7,-73.1,5,-78.1C20.7,-83,41.4,-72.5,56.8,-56.9Z"
          transform="translate(100 100)"
        />
      </svg>
    ),
  },
]

const CardList = () => {
  const [formInput, toggleForm] = useState()
  const scrollRef = useRef(null)

  const setFormInput = input => {
    scrollRef.current.scrollIntoView()
    toggleForm(input)
  }

  return (
    <CardListContainer ref={scrollRef}>
      <form name="schedule-form" netlify data-netlify="true" hidden>
        <input type="text" name="full-name" />
        <input type="number" name="zip-code" />
        <input type="email" name="email" />
        <input type="number" name="phone" />
        <input type="checkbox" name="call-me" />
        <input type="hidden" name="service" />
        <textarea name="work-desc"></textarea>
      </form>
      {formInput ? (
        <ScheduleForm service={formInput} />
      ) : (
        serviceList.map(service => (
          <Card
            title={service.title}
            desc={service.desc}
            img={service.img}
            toggleForm={setFormInput}
          />
        ))
      )}
    </CardListContainer>
  )
}

export default CardList
