import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = ({ className, image, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allImages: allFile {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              relativePath
            }
          }
        }
      }
    `
  )

  // Set ImageData
  const imageData = data.allImages.edges.find(n =>
    n.node.relativePath.includes(image)
  )

  return (
    <GatsbyImage
      image={getImage(imageData.node.childImageSharp)}
      alt={image || "lawnmower.jpg"}
    />
  )
}

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`

export default StyledImage
