import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import StyledImage from "./Image"

const CardContainer = styled.div`
  position: relative;
  background: #fff;
  height: 216px;
  width: auto;
  min-width: 400px;
  max-width: 600px;
  margin: 2rem;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgb(50 50 93 / 25%),
    0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%);
  .text-container {
    width: 67%;
    padding: 0.2rem 0 0 1rem;
    h3 {
      margin: 0.4rem 0;
      text-align: center;
    }

    p {
      font-size: 0.8rem;
      line-height: 1;
    }
  }
  button {
    position: absolute;
    bottom: 0.4rem;
    background-color: #0043ce;
    color: #ffffff;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    margin: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    &:hover {
      box-shadow: 0 30px 60px -12px rgb(50 50 93 / 25%),
        0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%);
    }
  }

  @media (max-width: 799px) {
    margin: 2rem 0;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    border-radius: 0;
  }
`

const Card = ({ title, desc, img, toggleForm }) => {
  return (
    <CardContainer>
      <div className="text-container">
        <h3>{title}</h3>
        <p>{desc}</p>
        <button onClick={() => toggleForm(title)}>Request</button>
      </div>
      <StyledImage image={img || "lawnmower.jpg"} />
    </CardContainer>
  )
}

export default Card
