import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  ChatBot32,
  LicenseDraft32,
  Delivery32,
  ZoomInArea32,
  Purchase32,
  ArrowDown32,
  EventSchedule32,
} from "@carbon/icons-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import AdditionalServices from "../components/additionalServices"
import { area, siteName } from "../info"
import Card from "../components/card"
import CardList from "../components/cardList"

const HowItWorks = styled.section`
  @media (min-width: 800px) {
    margin-top: 144px;
  }
  margin-top: 244px;
  margin-bottom: 4rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  h2 {
    text-align: center;
    margin: auto;
    border-bottom: 2px solid #284778;
    width: 75%;
  }
  ul {
    padding: 0.4rem 0;
    background-color: white;
    color: black;
    border-radius: 4px;
    list-style: none;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li {
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-size: 0.7rem;
      text-align: center;
      @media (min-width: 800px) {
        flex-direction: initial;
        font-size: 1rem;
      }
    }
  }
`
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />
    <CardList />
    <HowItWorks>
      <h2>How It Works</h2>
      <ul>
        <li>
          <ChatBot32 />
          We connect you with a handyman in {area} who will give you a free
          estimation.
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <LicenseDraft32 />
          Draft out what the work that will be done so there is a mutual
          understanding.
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <Delivery32 /> A handyman will visit and start working on your
          project.
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <ZoomInArea32 /> Inspect our work, give us feedback and your thoughts
          if any.
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <Purchase32 />
          We'll walk you through the work we have done and once you are
          satisfied send the payment.
        </li>
      </ul>
    </HowItWorks>
    <AdditionalServices />
    {/*<section>
      <h2>About Us</h2>
      <p>
        We and {siteName} are devoted to connect you with a handyman that fits
        your criteria.
      </p>
    </section>*/}
    {/*<section>
      <p style={{ padding: "1rem", textAlign: "center" }}>
        <EventSchedule32 style={{ display: "block", margin: "auto" }} />
        Store open from 10:am to 10:pm.
      </p>
    </section>*/}
  </Layout>
)

export default IndexPage
